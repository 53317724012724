<template>
  
<div class="bg" :style="bgStyle">
      <el-row>
    <el-col class="col-bg" :span=10 :offset="7">
        <h1>Story</h1>
        <el-divider id="drop">
            <svg t="1652001541498" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2043" width="20" height="20"><path d="M1024.4 648.6c0 205.9-169.4 375.4-375.3 375.4S273.7 854.6 273.7 648.6c0-205 157-358 267.9-500.2C568.9 113.5 609 96 649.1 96s80.2 17.5 107.4 52.4c110.9 142.2 267.9 295.3 267.9 500.2z" p-id="2044" fill="var(--color-blue)"></path><path d="M398 257.3c0 95.9-78.9 174.7-174.7 174.7-95.9 0-174.7-78.9-174.7-174.7 0-95.4 73.1-166.7 124.7-232.9C186 8.1 204.6 0 223.3 0c18.6 0 37.3 8.1 50 24.4C324.9 90.6 398 161.8 398 257.3zM192 633.3c0 52.7-43.3 96-96 96S0 686 0 633.3c0-52.4 40.2-91.6 68.5-127.9 7-8.9 17.2-13.4 27.5-13.4s20.5 4.5 27.5 13.4c28.3 36.4 68.5 75.5 68.5 127.9z" p-id="2045" fill="var(--color-blue)"></path></svg>
    </el-divider>
      <h3>我被那深不见底的“蓝色”诅咒了。</h3>
<h3>三月之初，春雨纷纷，名为“雨”的少女沐浴在潮湿的空气之中。</h3>
<h3>她被苍之魔女所诅咒，一旦她想从这个世界逃离，她就会回到那片蓝色的不冻湖深处。</h3>
<h3>堕落的少女在潜意识当中寻求着救赎之路。</h3>
<h3>请“你”牵起那只伤痕累累的手，将她从诅咒之中拯救出来吧。</h3>
<h3>亦或者，将她推入更深的蓝色之中……</h3>
    </el-col>
  </el-row>

</div>
</template>

<script>
export default {
  name: "rdp-story",

setup() {
    //const bigImgUrl = ref('')
    let bgStyle = {
      backgroundImage: 'url('+require("../assets/dialog_frame.png")+')',
      backgroundSize:'90% 140%',
      backgroundRepeat:'no-repeat',
      backgroundPosition:'center'
    }
    return {bgStyle}
}
}
</script>
<style scoped>
.bg {
    height:650px;
    width:100%;
}
.col-bg {
    /* background-color: #646f7d;
    border-radius:30px; 
    padding: -10px;*/
    margin-top: 100px;
    color:#006bfd

}
h1 {
    font-size:40px;
    margin-top:10px;
    margin-bottom:15px;
    font-family: gothic, SourceHanSerifCN-Medium;
    color:#edf6ff;

}
h3 {
    line-height:20px;
    font-family: SourceHanSerifCN-Medium;
    color:#edf6ff;
}
</style>