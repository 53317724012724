<template>
  <div class="common-layout">
    <el-container>
      <el-header class="dark-theme" >
        <el-row :gutter="15" >
          <el-col style="width=95%" :span=10 :offset=13><header-navi></header-navi></el-col>
        </el-row>
      </el-header>
      <el-main>
        <home-view></home-view>
      </el-main>
          
    </el-container>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderNavi from './components/header-navi.vue'

import HomeView from './views/HomeView.vue'
export default {
  name: 'app',
  components: {
    HomeView,
    HeaderNavi
  }
}
</script>
<style>
.el-container {
  min-height: 98vh;
}
.el-main {
  height:400px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-light-font);
  font-family: gothic, SourceHanSerifCN-Medium;
}

nav {
  padding: 20px;
}

nav a {
  font-weight: bold;
  color: var(--color-dark);
}

nav a.router-link-exact-active {
  color: #42b983;
}
.dark-theme {
  color: var(--color-light);
  padding-left:0px;
  padding-right: 0px;
  background-color:var(--color-dark);
}

.dark-theme a{
  color: var(--color-light);
  text-decoration: none;
    background-color:var(--color-mid);
}
.dark-theme a i{
    background-color:var(--color-mid);
}
svg{
  background-color: var(--color-dark);
}
#drop div{
  padding-left:10px;
  padding-right:10px;
  background-color: var(--color-dark);
}
body {
  /* background-color:#37474f; 
  color: #cfd8dc */
  background-color: var(--color-dark);
  color: var(--color-light);
}
[v-cloak] { display: none } 
.row-95{
  width: 95%;
}
a {
  color: var(--color-light-font);
  text-decoration: none;
}
a hover{
  color: var(--color-blue);
  text-decoration: underline;
}
:root {
  --color-dark: #2f4478;
  --color-mid: #4476a5;
  --color-weak: #9fb6cb;
  --color-light:#cfd8dc;
  --color-light-font:#edf6ff;
  --color-blue: #006bfd;
  --block-margin-buttom-:100pxp;
}
.bg{
  margin-top:30px;
  margin-bottom:30px;
}
</style>
