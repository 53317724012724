<template>
<div class="div-margin"> 
  
  <el-divider id="drop">
            <svg t="1652001541498" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2043" width="20" height="20"><path d="M1024.4 648.6c0 205.9-169.4 375.4-375.3 375.4S273.7 854.6 273.7 648.6c0-205 157-358 267.9-500.2C568.9 113.5 609 96 649.1 96s80.2 17.5 107.4 52.4c110.9 142.2 267.9 295.3 267.9 500.2z" p-id="2044" fill="var(--color-blue)"></path><path d="M398 257.3c0 95.9-78.9 174.7-174.7 174.7-95.9 0-174.7-78.9-174.7-174.7 0-95.4 73.1-166.7 124.7-232.9C186 8.1 204.6 0 223.3 0c18.6 0 37.3 8.1 50 24.4C324.9 90.6 398 161.8 398 257.3zM192 633.3c0 52.7-43.3 96-96 96S0 686 0 633.3c0-52.4 40.2-91.6 68.5-127.9 7-8.9 17.2-13.4 27.5-13.4s20.5 4.5 27.5 13.4c28.3 36.4 68.5 75.5 68.5 127.9z" p-id="2045" fill="var(--color-blue)"></path></svg>
    </el-divider>
      <el-footer>
    <el-row>
      <el-col :span="24"><div class="cp-right"><p>网页推荐分辨率：1600*900</p></div></el-col>
      </el-row><el-row>
    <el-col :span="24"><div class="cp-right"><p>Copyright © 2022 - Rain_drop_Project</p></div></el-col>
  </el-row>
  <el-row>
    <el-col :span="24"><div class="cp-right"><p><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022036269号</a></p></div></el-col>


  </el-row>
  </el-footer>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "rdp-footer",
  setup() {
    const activeIndex = ref("1");
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath);
    };
    return { activeIndex, handleSelect };
  },
};
</script>
<style scoped>
.cp-right {
  height: "90px";
  width:100%
}
.cp-right p {
  margin: 0px;
  padding: 5px 0px;
  font-size: 15px;
}
.cp-right a {
  color: rgb(120, 144, 156);
  text-decoration: none;
}
.div-margin{
  margin-top: 50px;
}
</style>