<template>
<el-scrollbar>
  <div class="common-layout homeview">
        <hello-world/> 
        <rdp-story/>
        <rdp-gallary/>
        <rdp-about/>
        <rdp-footer/>
  </div>
        
</el-scrollbar>
</template>
<script>
// @ is an alias to /src

import HelloWorld from "../components/HelloWorld.vue";
//import rdpVideoplay from "../components/videoplay.vue";
import rdpGallary from "../components/gallary.vue";
import rdpStory from "../components/story_tab.vue";
import rdpAbout from "../components/about_us.vue";
import rdpFooter from '../components/footer.vue'
export default {
  name: 'HomeView',
  components:{
    HelloWorld,
    rdpGallary,
    rdpStory,
    rdpAbout,
    rdpFooter
  }
}
</script>
<style scoped>
.na {
 height: 30px
}
</style>