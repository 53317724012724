<template>
  <div class="video-container">
    <!-- 
                    <div data-vjs-player="true" id="vjs_video" playsinline="true" 
                    class="video-js vjs-16-9 vjs-controls-enabled vjs-workinghover vjs-v7" tabindex="-1" aria-label="Video Player">
                        <video id="my-video" class="video-js vjs-big-play-centered" playsinline="playsinline"
                            controls preload="auto"
                            height='550' 
                            poster="../assets/ci-img/ci-heroine.png" data-setup="{}">
                                <source src="../assets/highway-loop.mp4" type="video/mp4"/>
                                <p class="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a
                                web browser that
                                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                </p>
                        </video>
                    </div>
                    <iframe class="video-frame" src="//player.bilibili.com/player.html?aid=461618399&bvid=BV1yL411W7Jv&cid=368368802&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
-->
                    <div class="video-frame">
                    <iframe  src="//player.bilibili.com/player.html?aid=461618399&bvid=BV1yL411W7Jv&cid=368368802&page=1" allowfullscreen="allowfullscreen" width="100%" height="100%" scrolling="no" frameborder="0" sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe>
                    </div>
                </div>
</template>

<script>
export default {
  name: "rdp-videoplay"
};
</script>

<style scoped>
.video-container {
  min-height: 550px;
}
.video-frame {
  width: 100%;
  height: 62.5vw;
}
</style>