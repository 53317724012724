<template>
<div class="bg">
<el-row>
              <el-col :span="24" >
                
                    <div class="grid-content">
                      <el-image class="img-stand" :src="mainimg"/>
                    </div>
                </el-col>
</el-row>
    </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  setup(){
    const mainimg = require("../assets/main.png")
    return {mainimg}
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.el-row {
  margin-bottom: 20px;
  height: 50%
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #e5e9f2;
}
.bg-purple {
  background: #d3dce6;

}
.bg-purple-light {
  background: #e5e9f2;
    color:#99a9bf;
}
.grid-content {
  border-radius: 4px;
  min-height: 200px;
  /* height:68vh; */
  width:100%;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.img-stand{
  height: 100%; 
  margin-top: 30px;
}
.logo-stand{
  height: 50%; 
  margin-top: 300px;
}

.testframe{
  display: none;
  border: 10px solid;
  height:100px;
  z-index:99;
  position: relative;
  top:200px;
  align-items: right;
}
.nav-li{
  flex-direction: column;
  display: flex;
  height: 300px;
}
</style>
