<template>

  <div>
      
        <el-row class="row-bg" justify="center" width="900px">
          <el-col :span="2" class="bgm-ctl" v-cloak>
            <span  v-cloak v-show="isPlay" @click="audioPlay"><el-icon><BellFilled /></el-icon></span>
            <span  v-cloak v-show="!isPlay" @click="audioPlay"><el-icon><MuteNotification /></el-icon></span>
            </el-col>
                <el-col :span="22">
            <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
      background-color="var(--color-mid)"
  text-color="#fff"
  active-text-color="#FFA726"
  >
    <el-menu-item index="1"><el-icon><pouring class="icon-bg"/></el-icon>
    首页
    </el-menu-item>
    <el-menu-item index="2"><el-icon><Notebook class="icon-bg"/></el-icon>
    故事梗概
    </el-menu-item>
    <el-menu-item index="3"><el-icon><avatar class="icon-bg"/></el-icon>
    人物介绍
    </el-menu-item>
    <el-menu-item style="display:none" index="4" disabled> <el-icon><download class="icon-bg"/></el-icon>试玩体验</el-menu-item>
    <el-menu-item index="5"> <el-icon><WarningFilled class="icon-bg"/></el-icon>关于我们</el-menu-item>
  </el-menu>
          </el-col>
          
        </el-row>       
  </div>
</template>

<script>
import { ref } from 'vue'
export default{
    name: "rdp-navi",
    setup(){
        const activeIndex = ref('1')
        const isPlay = ref('false')
        const audio = new Audio()
        audio.src= require('../assets/audio/RAINDROP_v1.mp3')
        audio.loop = true   
        audio.volume = 0.75
        const handleSelect = (key, keyPath) => {
            console.log(key, keyPath)
            let keynum = parseInt(key)
            if(keynum=='5'){
              document.querySelector('.about-row div').scrollIntoView()
            }else{
              document.querySelector(`.homeview div:nth-child(${keynum})`).scrollIntoView()
            }
        }
        return {audio,activeIndex,isPlay,handleSelect}
        },
    mounted(){
        window.addEventListener('scroll',this.onscroll)
      },
      created(){
        this.audio.autoPlay = true
        this.isPlay = true
        this.audio.addEventListener('ended', ()=>{
          console.log("audio end")
          this.isPlay = false
        })
        this.audioPlay()
      },
    destroy(){
      window.removeEventListener('scroll',this.onscroll)
    },
    methods:{
      onScroll(){
            // 获取所有锚点元素
        const navContents = document.querySelectorAll('.content div')
        // 所有锚点元素的 offsetTop
        const offsetTopArr = []
        navContents.forEach(item => {
          offsetTopArr.push(item.offsetTop)
        })
        // 获取当前文档流的 scrollTop
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 定义当前点亮的导航下标
        let navIndex = 0
        for (let n = 0; n < offsetTopArr.length; n++) {
          // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
          // 那么此时导航索引就应该是 n 了
          if (scrollTop >= offsetTopArr[n]) {
            navIndex = n
          }
        }
        // 把下标赋值给 vue 的 data
        this.active = navIndex
      },
      audioPlay() {
        console.log("isPlay ing? "+ this.isPlay)
        if (this.isPlay == false){
          this.audio.play() // 这里使用了audio的原生开始播放事件,同样不加on, 并使用ref获取dom
          this.isPlay = true
        }
        else {
          this.audio.pause()
          this.isPlay = false
        }
        console.log("isPlay ing? "+ this.isPlay)
      }
    }


}

</script>

<style scoped>
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.el-menu-demo{
  border-bottom-color: #90A4AE;
}

ul svg{
  background-color: var(--color-mid)
}
.bgm-ctl{
  margin-top:20px;
}
</style>