import { createRouter, createWebHashHistory } from 'vue-router'

import rdpVideoplay from '../components/videoplay.vue'
import HelloWorld from '../components/HelloWorld.vue'
import rdpGallary from '../components/gallary.vue'

const routes = [
  {
    path: '/',
    name: 'helloworld',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HelloWorld
  },
  {
    path: '/videoplay',
    name: 'videoplay',
    component: rdpVideoplay
  },
  {
    path: '/gallary',
    name: 'gallary',
    component: rdpGallary
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
