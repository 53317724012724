<template>
  
<div class="bg">
  <!--
    <el-row class="row-bg" justify="center">
      
    <el-col :span="6"><div class="grid-content bg-purple" /></el-col>
    <el-col :span="12">
    <el-carousel :interval="8000" type="card" height="540px" width="800px">
    <el-carousel-item v-for="img,index in srcList" :key="img">
      <el-image
      style="width: 95%; height: 95%; margin-top:2%;"
      :initial-index="index"
      :src="img"
      fit="contain"
      :teleported="false"
      @click="showBigImg(index)"
    />
    </el-carousel-item>
  </el-carousel> </el-col>
    
    <el-col :span="6"><div class="grid-content bg-purple" /></el-col>
  </el-row>-->
<el-image-viewer @close="hideViewer" v-if="showviewer" :url-list="bigImg" :hide-on-click-modal="true"></el-image-viewer>
  
  <el-carousel indicator-position="outside" :autoplay="false" :loop="false">
    <el-carousel-item>
  <el-row class="row-bg" justify="center">
    <el-col class="col-margin" v-for="(itm, index ) in srcList_l1" :key="itm" :span="4" >
      <el-card :body-style="{ padding: '0px'}">
        <img :src= "itm.tb" class="image" @click="showBigImg(index)"/>
        <div style="padding: 14px; display:none;">
          <span class="card-text">{{itm.name}}</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
    </el-carousel-item>
    <el-carousel-item>
    <el-row class="row-bg" justify="center">
    <el-col class="col-margin" v-for="(itm, index ) in srcList_l2" :key="itm" :span="4" 
    :offset="index == 0 ? 7 : 18">
      <el-card :body-style="{ padding: '0px' }">
        <img :src= "itm.tb" class="image" @click="showBigImg(index+5)"/>
        <div style="padding: 14px; display:none">
          <span class="card-text">{{itm.name}}</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
    </el-carousel-item>
</el-carousel>

</div>
</template>
<script>
import{ ref } from 'vue'
export default {
  name: "rdp-gallary",

  setup() {
    let showviewer = ref(false)
    //const bigImgUrl = ref('')
    const portraitmap= [
      {"name":"Qin",
      "tb": require("../assets/ci-img-thumbs/ci-qin-tb.png"),
      "url":require("../assets/ci-img/ci-qin.png")},
      { "name":"Ai",
      "tb": require("../assets/ci-img-thumbs/ci-ai-tb.png"),
      "url":require("../assets/ci-img/ci-ai.png")},
      {"name":"Heroine",
      "tb": require("../assets/ci-img-thumbs/ci-heroine-tb.png"),
      "url":require("../assets/ci-img/ci-heroine.png")},
      {"name":"Iris",
      "tb": require("../assets/ci-img-thumbs/ci-iris-tb.png"),
      "url":require("../assets/ci-img/ci-iris.png")},
      {"name":"Doll",
      "tb": require("../assets/ci-img-thumbs/ci-doll-tb.png"),
      "url":require("../assets/ci-img/ci-doll.png")},
      {"name":"Rain Blued",
      "tb": require("../assets/ci-img-thumbs/ci-rainblued-tb.png"),
      "url":require("../assets/ci-img/ci-rainblued.png")},
      { "name":"Violet",
      "tb": require("../assets/ci-img-thumbs/ci-violet-tb.png"),
      "url":require("../assets/ci-img/ci-violet.png")}
      ];
    let srcList = []
    let srcList_l1 = []
    let srcList_l2 = []
    portraitmap.forEach(function(val,index){
      srcList.push(val.url)
      if(index <5){
        srcList_l1.push(val)
        }else{
          srcList_l2.push(val)
        }
    })
    let bigImg = ref([])
    const showBigImg = (idx)=>{
      showviewer.value = true
      //sort list to align the showing sequence
      bigImg.value = srcList.slice(idx).concat(srcList.slice(0,idx))
      console.log(bigImg.value)
    }
    const hideViewer = ()=>{
      showviewer.value = false
    }
    return {srcList,srcList_l1,srcList_l2,showBigImg,hideViewer,showviewer,bigImg}
  }
};
</script>
<style scoped>
.el-carousel{
  background-color: var(--color-dark);
  border-color: var(--color-mid);
  height: 30vw;
}
.el-carousel__item{
  width:100%;
  height:auto;
}
.el-icon {
  color:var(--color-blue);
  height:5em;
  width:5em;
}
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 80%;
  height: 200px;
}
.demo-image__preview{
  width: 800px;
  height:80%;
}


.is-active{
  transform: scale(5);
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.row-bg {
  width:"100%";
  background-color: var(--color-dark);
}
.col-margin{
  margin-left: 1.2vw;
  margin-right:1.2vw;
  margin-bottom:4vh;
  border:'none';
}
.card-text{
  font-size: 20px;
  color:#475669;
}
.el-card{
  --el-card-bg-color: var(--color-dark);
  --el-card-border-color: var(--color-dark);
  --el-card-border-radius: 0px;
}
.bg{
  margin-top: 50px;
  margin-bottom: 0px
}

</style>