import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ELIcons from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'

const app = createApp(App)
for (const name in ELIcons){
    app.component(name,(ELIcons)[name])
}
app.use(ElementPlus)
app.use(ELIcons)
app.use(router)
app.mount('#app')

